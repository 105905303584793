import { request, envConfig } from './utils/request';
import { getLocalISOString } from './utils/date';
import { uuid } from './utils/string';
import DebounceBuffer from './utils/DebounceBuffer';
import Member from './Member';
import Staff from './Staff';
import wxWeapp from './WxWeapp';

/**
 * 微信小程序类
 */
class Weapp {
  /**
   * 创建 Weapp 实例
   */
  constructor() {
    this._buffer = new DebounceBuffer(reports => request.post('/v2/weapp/formIdReports', { reports }));
  }

  /**
   * 上报微信小程序 formId，需要授权 {@link Member#signin}
   *
   * @param {string} formId - 提交表单时的 formId
   */
  reportFormId(formId) {
    Weapp.validate(formId);
    const data = {
      formId,
      createdAt: getLocalISOString(),
    };
    this._buffer.debounce(data);
  }

  /**
   * 上传临时文件
   *
   * @param {string} openId - 用户的 openId，仅作唯一标识文件使用
   * @param {string} tempFilePath - 微信小程序临时文件路径
   * @param {object} ossConfig - oss 相关配置，为空时会自动获取
   * @returns {promise}
   */
  async uploadTempFile(openId, tempFilePath, ossConfig = null) {
    let checkedOssConfig = { ...ossConfig };
    if (!ossConfig) {
      checkedOssConfig = await request.get('/v2/oss/signature');
    }

    const source = tempFilePath;
    const ext = /\.\w+$/.exec(source)[0];
    const file = `${checkedOssConfig.directory}/followers/${openId}/${uuid()}${ext}`;
    await wxWeapp.uploadFile({
      url: checkedOssConfig.domain,
      filePath: source,
      name: 'file',
      formData: {
        OSSAccessKeyId: checkedOssConfig.accessKeyId,
        policy: checkedOssConfig.policy,
        Signature: checkedOssConfig.signature,
        key: file,
        success_action_status: 200,
      },
    });

    return {
      source,
      url: `${checkedOssConfig.domain}/${file}`,
    };
  }

  /**
   * 上传图片，参数参考微信小程序 chooseImage 定义，需要授权 {@link Member#signin}
   *
   * @param {object} options
   * @param {number} options.count - 最多可以选择的图片张数
   * @param {"original" | "compressed"} options.sizeType - 所选的图片的尺寸
   * @param {"album" | "camera"} options.sourceType - 选择图片的来源
   * @param {boolean} options.needImageInfo - 是否需要返回图片信息
   * @returns {array}
   */
  async uploadImages(options) {
    if (!options.count) {
      throw new Error('count is required');
    }

    const { tempFilePaths } = await wxWeapp.chooseImage(options);
    try {
      const member = new Member();
      const staff = new Staff();
      const openId = member.getOpenId() || staff.getOpenId();
      const items = [];
      wx.showLoading({ mask: true, title: '上传中...' });
      const ossConfig = await request.get('/v2/oss/signature');
      for (let index = 0; index < tempFilePaths.length; index += 1) {
        // eslint-disable-next-line
        const item = await this.uploadTempFile(openId, tempFilePaths[index], ossConfig, index);
        if (options.needImageInfo) {
          // eslint-disable-next-line
          const { type, height, width } = await wxWeapp.getImageInfo({ src: item.source });
          item.type = type;
          item.height = height;
          item.width = width;
        }
        items.push(item);
      }

      return items;
    } finally {
      wx.hideLoading();
    }
  }

  /**
   * 通过微信小程序绑定手机号，需要授权 {@link Member#signin}
   *
   * @param {object} options
   * @param {string} options.code - [用户登录凭证](https://developers.weixin.qq.com/miniprogram/dev/api/wx.login.html)
   * @param {string} options.iv [加密算法的初始向量](https://developers.weixin.qq.com/miniprogram/dev/framework/open-ability/getPhoneNumber.html)
   * @param {string} options.encryptedData [包括敏感数据在内的完整用户信息的加密数据](https://developers.weixin.qq.com/miniprogram/dev/framework/open-ability/getPhoneNumber.html)
   * @param {boolean} options.allowMerge 是否允许自动 merge 手机号相同的 member。
   * @returns {object}
   */
  async bindPhone(options) {
    if (!options.code) {
      throw new Error('code is required');
    }

    if (!options.iv) {
      throw new Error('iv is required');
    }

    if (!options.encryptedData) {
      throw new Error('encryptedData is required');
    }

    const member = await request.put('/v2/member/bindPhoneFromMiniProgram', options);
    Member.instance.updateAs(member);
    return member;
  }

  /**
   * 微信小程序的 version 可以从 request referer 中获取，详情参考 [微信开发者文档](https://developers.weixin.qq.com/miniprogram/dev/framework/ability/network.html)
   *
   * @returns {string} 取值范围 undefined | devtools | 0 | N+
   * - 如果未 signin 则返回 undefined
   * - 如果使用开发工具，则返回 devtools
   * - 如果是开发版或者体验版，则返回 0
   * - 如果是正式版，则返回 N+，且随着每次发布自增
   */
  getVersion() {
    return envConfig.weappVersion;
  }

  /**
   * 验证参数类型
   *
   * @private
   * @param {string} formId - 提交表单时的 formId
   */
  static validate(formId) {
    if (!formId) {
      throw new Error('formId is required');
    }
  }
}

export default Weapp;
