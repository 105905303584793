const CONSUMER_API_ENV_URL = {
  local: 'http://ajax.wm.com:9093',
  staging: 'https://staging-consumer-api.quncrm.com',
  production: 'https://consumer-api.quncrm.com',
  'usp-staging': 'https://staging-consumer-api.usocialplus.com',
  'usp-production': 'https://consumer-api.usocialplus.com',
  'masterkong-production': 'https://consumer-api.masterkong.com.cn',
  'dyk-production': 'https://scrm-consumer-api.dyk.com.cn',
  // HACK: jingmai 情况特殊，实际没有 consumer-api，为了防止因 env 无效而无法实例化 Mai，这里使用假的
  // 参考 https://gitlab.maiscrm.com/mai/sre/issues/1919
  'jingmai-staging': 'https://staging-kol-api.maiscrm.com',
  'jingmai-production': 'https://kol-api.maiscrm.com',
  'bsd-production': 'https://task-consumer-api.bsdits.cn',
  'aliyunqa-production': 'https://aliyunqa-consumer-api.maiscrm.com',
  'henkel-production': 'https://scrm-consumer-api.schwarzkopf.maiscrm.com',
  'mdlz-production': 'https://scrm-consumer-api.emdlz.com.cn',
};

const OAUTH_ENV_URL = {
  local: 'http://oauth.wm.com',
  staging: 'https://staging-oauth.quncrm.com',
  production: 'https://oauth.quncrm.com',
  'usp-staging': 'https://staging-oauth.usocialplus.com',
  'usp-production': 'https://oauth.usocialplus.com',
  'masterkong-production': 'https://oauth.masterkong.com.cn',
  'dyk-production': 'https://scrm-oauth.dyk.com.cn',
  'bsd-production': 'https://task-oauth.bsdits.cn',
  'aliyunqa-production': 'https://aliyunqa-oauth.maiscrm.com',
  'henkel-production': 'https://scrm-oauth.schwarzkopf.maiscrm.com',
  'mdlz-production': 'https://scrm-oauth.emdlz.com.cn',
};

const BUSINESS_API_ENV_URL = {
  local: 'http://ajax.wm.com:9091',
  staging: 'https://staging-business-api.quncrm.com',
  production: 'https://business-api.quncrm.com',
  'usp-staging': 'https://staging-business-api.usocialplus.com',
  'usp-production': 'https://business-api.usocialplus.com',
  'masterkong-production': 'https://business-api.masterkong.com.cn',
  'dyk-production': 'https://scrm-business-api.dyk.com.cn',
  'bsd-production': 'https://task-business-api.bsdits.cn',
  'aliyunqa-production': 'https://aliyunqa-business-api.maiscrm.com',
  'henkel-production': 'https://scrm-business-api.schwarzkopf.maiscrm.com',
  'mdlz-production': 'https://scrm-business-api.emdlz.com.cn',
};

const CDN_ENV_URL = {
  local: 'https://dev-statics.maiscrm.com',
  staging: 'https://staging-statics.maiscrm.com',
  production: 'https://statics.maiscrm.com',
  'usp-staging': 'https://staging-statics.usocialplus.com',
  'usp-production': 'https://statics.usocialplus.com',
  'masterkong-production': 'https://masterkong-production-statics.quncrm.com',
  'dyk-production': 'https://scrm-statics.dyk.com.cn',
  'bsd-production': 'https://task-statics.bsdits.cn',
  'aliyunqa-production': 'https://aliyunqa-statics.maiscrm.com',
  'henkel-production': 'https://scrm-statics.schwarzkopf.maiscrm.com',
  'mdlz-production': 'https://scrm-statics.emdlz.com.cn',
};

export {
  CONSUMER_API_ENV_URL,
  BUSINESS_API_ENV_URL,
  OAUTH_ENV_URL,
  CDN_ENV_URL,
};
