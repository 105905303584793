import { OAUTH_ENV_URL } from './utils/env';
import { isQyWeapp } from './utils/lang';
import { request, envConfig, setBaseUrl, setAccessToken, setAccountId } from './utils/request';
import wxWeapp from './WxWeapp';

/**
 * 员工类
 */
class Staff {
  /**
   * 当前渠道 ID
   *
   * @private
   * @type {string}
   */
  _channelId = '';

  /**
   * 当前渠道 openId
   *
   * @private
   * @type {string}
   */
  _openId = '';

  /**
   * 创建员工实例（单例）
   */
  constructor() {
    const { instance } = Staff;
    if (instance) {
      return instance;
    }
    Staff.instance = this;
    return this;
  }

  /**
   * 员工 OAuth
   *
   * @param {object} options - 选项
   * @param {string} [options.weappId] - 小程序的 appId
   * @param {string} [options.appId] - 企业微信应用 agentId
   * @param {string} [options.corpId] - 企业微信的 cropId
   * @param {string} [options.code] - 企业微信小程序[用户登录凭证](https://open.work.weixin.qq.com/api/doc/90000/90136/91506)
   *
   * @returns {Promise<void>}
   */
  async signin(options = {}) {
    if (isQyWeapp) {
      let baseURL = OAUTH_ENV_URL[envConfig.env];
      if (envConfig.accountId) {
        baseURL = `${OAUTH_ENV_URL[envConfig.env]}/${envConfig.accountId}`;
      }
      if (!options.weappId && (!options.appId || !options.corpId)) {
        throw new Error('appId and corpId are required or weappId is required');
      }
      const data = {
        weappId: options.weappId,
        appId: options.appId,
        corpId: options.corpId,
        code: options.code,
      };
      if (!data.code) {
        const { code } = await wxWeapp.qy.login();
        data.code = code;
      }
      const auth = await request.post('/v2/work/weapp/oauth', data, { baseURL });
      if (!envConfig.accountId) {
        setAccountId(auth.accountId);
      }
      setBaseUrl(envConfig.businessApiBaseUrl);
      setAccessToken(auth.accessToken);
      this._accessToken = auth.accessToken;
      this._channelId = auth.channelId;
      this._openId = auth.openId;
    }
  }

  /**
   * 获取 channelId，需要授权 {@link Staff#signin}
   *
   * @returns {string}
   */
  getChannelId() {
    return this._channelId;
  }

  /**
   * 获取员工当前渠道 openId，需要授权 {@link Staff#signin}
   *
   * @returns {string}
   */
  getOpenId() {
    return this._openId;
  }

  /**
   * 获取 accessToken，需要授权 {@link Staff#signin}
   *
   * @private
   * @returns {string}
   */
  getAccessToken() {
    return this._accessToken;
  }
}

export default Staff;
