import qs from 'qs';
import { initRequest, outsideRequest, envConfig, setAccountId } from './utils/request';
import { CONSUMER_API_ENV_URL } from './utils/env';
import Member from './Member';
import Staff from './Staff';
import MemberEvent from './MemberEvent';
import Puppeteer from './Puppeteer';
import Log from './Log';
import Weapp from './Weapp';
import OssImage from './OssImage';
import Captcha from './Captcha';
import WxH5 from './WxH5';
import WxWeapp from './WxWeapp';
import { WeappAuthScope } from './Enums';

/**
 * maijs 类
 */
class Mai {
  /**
   * @type {Member}
   */
  member = null;

  /**
   * @type {Staff}
   */
  staff = null;

  /**
   * @type {Weapp}
   */
  weapp = null;

  /**
   * @type {MemberEvent}
   */
  memberEvent = null;

  /**
   * @type {Log}
   */
  log = null;

  /**
   * axiox 对象，使用方式参考 {@link https://github.com/axios/axios}
   * @type {AxiosInstance}
   */
  request = null;

  /**
   * @type {Puppeteer}
   */
  puppeteer = null;

  /**
   * @type {typeof WeappAuthScope}
   */
  WeappAuthScope = WeappAuthScope;

  /**
   * @type {typeof OssImage}
   */
  OssImage = OssImage;

  /**
   * @type {typeof Captcha}
   */
  Captcha = Captcha;

  /**
   * @type {WxH5}
   */
  wxH5 = null;

  /**
   * @type {WxWeapp}
   */
  wxWeapp = WxWeapp;

  /**
   * @type {typeof envConfig}
   */
  envConfig = envConfig;

  /**
   * @type {typeof qs}
   */
  qs = qs;

  /**
   * 根据 env 和 accountId 创建 maijs 实例
   *
   * @param {string} env - 环境变量，可选值 staging，production，usp-staging，usp-production，masterkong-production
   * @param {string} accountId - 群脉账号 id
   * @param {object} [options={}] -  可选参数
   * @param {string} [options.appName] - 应用名称，将拼接到日志的 category 中
   * @param {string} [options.appVersion] - 应用版本
   * @param {string} [options.oauthApiBaseUrl] - OAuth 网络请求 Base URL
   * @param {string} [options.consumerApiBaseUrl] - Consumer API 网络请求 Base URL
   * @param {string} [options.cdnBaseUrl] - CDN 资源 Base URL
   */
  constructor(env, accountId, options) {
    if (Mai._instance) {
      return Mai._instance;
    }
    // TODO 为 1.14 以前版本升级而做了兼容，当绝大数用户高于 1.14 版本后可以移除。
    const deprecatedEnvMap = {
      'scrm-staging': 'staging',
      'scrm-production': 'production',
    };
    if (deprecatedEnvMap[env]) {
      console.warn(`env "${env}" has been deprecated. Please update to "${deprecatedEnvMap[env]}" ASAP.`);
    }

    // eslint-disable-next-line no-param-reassign
    env = deprecatedEnvMap[env] || env;
    Mai.validate(env, accountId);
    initRequest(env, accountId, options);
    this.memberEvent = new MemberEvent();
    this.member = new Member();
    this.staff = new Staff();
    this.log = new Log();
    this.weapp = new Weapp();
    this.request = outsideRequest;
    this.puppeteer = new Puppeteer();
    this.wxH5 = new WxH5(env, accountId);

    Mai._instance = this;
    // eslint-disable-next-line no-console
    console.info(`maijs@${process.env.SDK_VERSION} initialized`);
  }

  /**
   * 设置租户 Id
   * @param {*} accountId
   */
  setAccountId(accountId) {
    setAccountId(accountId);
  }

  /**
   * 验证构造器参数类型
   *
   * @private
   * @param {string} env - 环境变量
   * @param {string} accountId - 群脉账号 id
   */
  static validate(env, accountId) {
    if (!env) {
      throw new Error('env is required');
    }
    if (!CONSUMER_API_ENV_URL[env]) {
      const envStr = Object.keys(CONSUMER_API_ENV_URL).join(', ');
      throw new Error(`The value of env must be one of ${envStr}`);
    }
    // 多租户共用一个企微小程序时，不会配置 accountId，此时需要在 staff.signin() 后设置 accountId
    if (!accountId && accountId !== false) {
      throw new Error('accountId is required');
    }
    if (accountId && accountId.length !== 24) {
      throw new Error('accountId is invalid');
    }
  }
}

export default Mai;
