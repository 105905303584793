import axios from 'axios';
import qs from 'qs';
import weappAdapter from './weappAxiosAdapter/adapter';
import { isWeapp } from './lang';
import { OAUTH_ENV_URL, BUSINESS_API_ENV_URL, CONSUMER_API_ENV_URL, CDN_ENV_URL } from './env';

/**
 * @type {axios}
 * @private
 */
let request = null;

// 为了实现外部代码添加 interceptors 不影响内部代码，需要添加 2 个 request
let outsideRequest = null;

// 确保 params 输出结果如 id[]=1&id[]=2&listCondition.page=1&listCondition.perPage=20
const paramsSerializer = params => qs.stringify(params, { arrayFormat: 'brackets', allowDots: true });

if (isWeapp) {
  const adapter = weappAdapter(axios);
  request = axios.create({
    adapter,
    paramsSerializer,
  });
  outsideRequest = axios.create({ adapter, paramsSerializer });
} else {
  request = axios.create({ paramsSerializer });
  outsideRequest = axios.create({ paramsSerializer });
}

/**
 * 环境配置
 *
 * @property {string} env - 环境
 * @property {string} accountId - 租户 Id
 * @property {string} oauthApiBaseUrl - OAuth 网络请求 Base URL
 * @property {string} consumerApiBaseUrl - Consumer API 网络请求 Base URL
 * @property {string} cdnBaseUrl - CDN 资源 Base URL
 */
const envConfig = {
  env: '',
  accountId: '',
  options: {
    appName: '',
    appVersion: '',
  },
  oauthApiBaseUrl: '',
  consumerApiBaseUrl: '',
  businessApiBaseUrl: '',
  cdnBaseUrl: '',
};

const initRequest = (env, accountId, options = {}) => {
  envConfig.env = env;
  envConfig.accountId = accountId;
  envConfig.options = options;
  envConfig.oauthApiBaseUrl = options.oauthApiBaseUrl || OAUTH_ENV_URL[env];
  envConfig.consumerApiBaseUrl = options.consumerApiBaseUrl || CONSUMER_API_ENV_URL[env];
  envConfig.businessApiBaseUrl = options.businessApiBaseUrl || BUSINESS_API_ENV_URL[env];
  envConfig.cdnBaseUrl = options.cdnBaseUrl || CDN_ENV_URL[env];

  // TODO: 以下 oauthAPIDomain、consumerAPIDomain、cdnDomain 为兼容代码，建议半年以后删除
  Object.defineProperty(envConfig, 'oauthAPIDomain', {
    enumerable: false,
    get() {
      console.warn('"oauthAPIDomain" has been deprecated, please update to "oauthApiBaseUrl" ASAP.');
      return envConfig.oauthApiBaseUrl;
    },
  });

  Object.defineProperty(envConfig, 'consumerAPIDomain', {
    enumerable: false,
    get() {
      console.warn('"consumerAPIDomain" has been deprecated, please update to "consumerApiBaseUrl" ASAP.');
      return envConfig.consumerApiBaseUrl;
    },
  });

  Object.defineProperty(envConfig, 'cdnDomain', {
    enumerable: false,
    get() {
      console.warn('"cdnDomain" has been deprecated, please update to "cdnBaseUrl" ASAP.');
      return envConfig.cdnBaseUrl;
    },
  });

  [request, outsideRequest].forEach((item) => {
    item.defaults.baseURL = envConfig.consumerApiBaseUrl;
    item.defaults.timeout = 3000;
    item.defaults.headers['X-Account-Id'] = accountId;
    item.defaults.headers['X-Requested-With'] = 'XMLHttpRequest';
    item.defaults.withCredentials = true;
    item.defaults.params = { maijsVersion: process.env.SDK_VERSION };
    if (options.appVersion) {
      item.defaults.params.appVersion = options.appVersion;
    }
    if (options.appName) {
      item.defaults.params.appName = options.appName;
    }
  });

  request.interceptors.response.use((res) => {
    if (res.headers && res.headers['x-weapp-version']) {
      envConfig.weappVersion = res.headers['x-weapp-version'];
    }

    // eslint-disable-next-line no-console
    console.debug('request result', { request: res.request, status: res.status, response: res.data });
    return res.data;
  }, (error) => {
    // eslint-disable-next-line no-console
    console.error('request error', { error, request: error.request, response: error.response });
    return Promise.reject(error);
  });

  outsideRequest.interceptors.response.use((res) => {
    // eslint-disable-next-line no-console
    console.debug('request result', { request: res.request, status: res.status, response: res.data });
    return res;
  }, (error) => {
    // eslint-disable-next-line no-console
    console.error('request error', { error, request: error.request, response: error.response });
    return Promise.reject(error);
  });
};

const setBaseUrl = (baseUrl) => {
  [request, outsideRequest].forEach((item) => {
    item.defaults.baseURL = baseUrl;
  });
};

const setAccessToken = (accessToken) => {
  [request, outsideRequest].forEach((item) => {
    item.defaults.headers['X-Access-Token'] = accessToken;
  });
};

const setAccountId = (accountId) => {
  envConfig.accountId = accountId;
  [request, outsideRequest].forEach((item) => {
    item.defaults.headers['X-Account-Id'] = accountId;
  });
};

export {
  initRequest,
  envConfig,
  request,
  outsideRequest,
  setBaseUrl,
  setAccessToken,
  setAccountId,
};
