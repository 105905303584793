const isObject = value => Object.prototype.toString.call(value) === '[object Object]';

const isFunction = value => Object.prototype.toString.call(value) === '[object Function]';

const isWeapp = !window && !document && !!wx;

const isQyWeapp = isWeapp && wx.getSystemInfoSync().environment === 'wxwork';

export {
  isObject,
  isFunction,
  isWeapp,
  isQyWeapp,
};
